import classnames from "classnames";
import styles from "./style.module.scss";

const InputText = ({
  type = "text",
  value,
  name,
  maxLength,
  placeholder,
  className,
  error,
  helpText,
  disabled,
  onFocus,
  onChange,
  onBlur,
  onPress,
  ...restProps
}) => {
  const handlerPress = (e) => {
    if (e.key === " " && type === "email") {
      e.preventDefault();
      return;
    }

    if (onPress) {
      onPress(e);
    }
  };

  return (
    <label
      className={classnames(styles.inputText, className, { error: !!error })}
    >
      <input
        disabled={disabled}
        id={name}
        name={name}
        maxLength={maxLength}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyPress={handlerPress}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        type={type}
        {...restProps}
      />
      {(error || helpText) && (
        <p
          className={styles.helpText}
          dangerouslySetInnerHTML={{
            __html: error || helpText,
          }}
        />
      )}
    </label>
  );
};

export default InputText;
