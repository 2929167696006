import classnames from "classnames";
import styles from "./style.module.scss";

const AnimationBlock = ({
  children,
  width = 440,
  className,
  marginTop = 40,
}) => (
  <section
    className={classnames(styles.section, className)}
    style={{ "--width": `${width}px`, "--marginVertical": `${marginTop}px` }}
  >
    {children}
  </section>
);
export default AnimationBlock;
