import styles from "./style.module.scss";
import classnames from "classnames";

const ScrollAnimation = ({ data = [], startAnimation = true, className }) => {
  const translate = 100 - 100 / data.length;

  return (
    <div
      className={classnames(styles.animation, className)}
      style={{ "--translate": `-${translate}%` }}
    >
      <p className={classnames({ startAnimation })}>
        {data.map((item) => (
          <span>{item}</span>
        ))}
      </p>
    </div>
  );
};

export default ScrollAnimation;
