import React from "react";
import styles from "./style.module.scss";

const Loader = () => (
  <div className={styles.ring}>
    <div />
    <div />
    <div />
    <div />
  </div>
);

export default Loader;
