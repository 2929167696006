import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import image from "src/static/images/second-step/screen.png";
import imageWebp from "src/static/images/second-step/screen.webp";
import imageSafari from "src/static/images/second-step/Group 30436.png";
import imageSafariWebp from "src/static/images/second-step/Group 30436.webp";
import image2x from "src/static/images/second-step/screen-2x.png";
import image2xWebp from "src/static/images/second-step/screen-2x.webp";
import imageSafari2x from "src/static/images/second-step/Group 30436@2x.png";
import image2xSafariWebp from "src/static/images/second-step/Group 30436@2x.webp";
import videoChrome from "src/static/videos/FV-pining-desktop-ok.mp4";
import videoSafari1 from "src/static/videos/FV-safari-pin with right click.mp4";
import videoSafari2 from "src/static/videos/FV-safari-pin with menu.mp4";
import { useHistory } from "react-router-dom";
import { detect } from "detect-browser";
import { ReactComponent as Logo } from "../../svg/logo-welcome.svg";
import classesFirstStep from "../Welcome/compontents/styles/mainPage.module.scss";
import classes from "../Welcome/style.module.scss";

const SecondStep = ({ t }) => {
  const [isVideo, setIsVideo] = useState(false);
  const [isSecondVideo, setIsSecondVideo] = useState(false);

  const history = useHistory();

  const browser = detect().name;

  useEffect(() => {
    if (browser !== "safari" && browser !== "chrome") {
      history.push("/bienvenue/step-3");
    }
  }, [browser]);

  return (
    <div className={classes.root}>
      {isVideo && (
        <div className={classesFirstStep.videoWrapper}>
          <div>
            <button type="button" onClick={() => setIsVideo(false)}>
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.4057 10.8268C12.7621 11.1832 12.7621 11.761 12.4057 12.1173C12.0494 12.4737 11.4716 12.4737 11.1152 12.1173L6.64421 7.6463L2.17319 12.1173C1.81682 12.4737 1.23904 12.4737 0.882681 12.1173C0.526317 11.761 0.526317 11.1832 0.882681 10.8268L5.3537 6.35579L0.88268 1.88478C0.526316 1.52841 0.526317 0.950632 0.882681 0.594268C1.23904 0.237905 1.81683 0.237905 2.17319 0.594268L6.64421 5.06529L11.1152 0.594268C11.4716 0.237904 12.0494 0.237905 12.4057 0.594268C12.7621 0.950632 12.7621 1.52841 12.4057 1.88478L7.93471 6.35579L12.4057 10.8268Z"
                  fill="white"
                />
              </svg>
            </button>
            <video
              src={browser === "safari" ? videoSafari1 : videoChrome}
              controls
            />
          </div>
        </div>
      )}
      {isSecondVideo && (
        <div className={classesFirstStep.videoWrapper}>
          <div>
            <button type="button" onClick={() => setIsSecondVideo(false)}>
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.4057 10.8268C12.7621 11.1832 12.7621 11.761 12.4057 12.1173C12.0494 12.4737 11.4716 12.4737 11.1152 12.1173L6.64421 7.6463L2.17319 12.1173C1.81682 12.4737 1.23904 12.4737 0.882681 12.1173C0.526317 11.761 0.526317 11.1832 0.882681 10.8268L5.3537 6.35579L0.88268 1.88478C0.526316 1.52841 0.526317 0.950632 0.882681 0.594268C1.23904 0.237905 1.81683 0.237905 2.17319 0.594268L6.64421 5.06529L11.1152 0.594268C11.4716 0.237904 12.0494 0.237905 12.4057 0.594268C12.7621 0.950632 12.7621 1.52841 12.4057 1.88478L7.93471 6.35579L12.4057 10.8268Z"
                  fill="white"
                />
              </svg>
            </button>
            <video src={videoSafari2} controls />
          </div>
        </div>
      )}
      <div className={classNames(classes.header)}>
        <div className={classes.logo}>
          <a href="https://franceverif.fr/" target="_blank">
            <Logo />
          </a>
        </div>
      </div>
      <div className={classes.body}>
        <div className={classesFirstStep.root}>
          <div
            className={
              browser === "safari"
                ? classesFirstStep.imageSecondSafari
                : classesFirstStep.imageSecond
            }
          >
            <picture>
              <source
                srcSet={`${browser === "safari" ? imageSafariWebp : imageWebp} 1x, ${browser === "safari" ? image2xSafariWebp : image2xWebp} 2x`}
                type="image/webp"
              />
              <img
                src={browser === "safari" ? imageSafari : image}
                srcSet={`${browser === "safari" ? imageSafari2x : image2x} 2x`}
                type="image/png"
                alt={t("firstTitle")}
              />
            </picture>
          </div>
          <div className={classesFirstStep.content}>
            <div className={classesFirstStep.title}>
              Epinglez l’extension France Verif{" "}
              <i>{browser == "safari" ? "sur Safari" : "sur Google Chrome"}</i>
            </div>
            <p className={classesFirstStep.secondStepFirstText}>
              {browser === "safari" ? (
                <>
                  Si l’icône France Verif n’apparait pas automatiquement dans la
                  barre de votre navigateur (Safari), vous devez “l’épingler”.{" "}
                  <b>Rien de plus simple, voici comment faire:</b>
                </>
              ) : (
                <>
                  Si l’icône France Verif n’apparait pas automatiquement dans la
                  barre de votre navigateur (Chrome), vous devez “l’épingler”.{" "}
                  <b>Rien de plus simple, voici comment faire:</b>
                </>
              )}
            </p>
            <ul className={classesFirstStep.orderedList}>
              <li>
                <span>1</span>
                {browser === "safari" ? (
                  <span>
                    <b>
                      Faites un clic droit sur la barre supérieure et choisissez
                      “Personnaliser la barre d’outils”
                    </b>{" "}
                    (ou via le menu général “Présentation” > “Personnaliser la
                    barre d’outils”)
                  </span>
                ) : (
                  <span>Cliquez sur l’icône “Extensions”</span>
                )}
              </li>
              <li>
                <span>2</span>
                {browser === "safari" ? (
                  <span>
                    <b>Localisez, puis glissez l’icône France Verif</b> à droite
                    (ou à gauche) du champ de recherche
                  </span>
                ) : (
                  <span>Trouvez France Verif dans la liste</span>
                )}
              </li>
              <li>
                <span>3</span>
                {browser === "safari" ? (
                  <span>Cliquez sur “Terminé”</span>
                ) : (
                  <span>
                    Cliquez sur l’icône “Epingle” (l’icône devient bleue)
                  </span>
                )}
              </li>
            </ul>
            <p className={classesFirstStep.secondText}>
              <b>
                L’icône France Verif devrait maintenant apparaitre à côté de la
                barre de recherche. Est-ce bien le cas ?
              </b>
            </p>
            <div
              className={classesFirstStep.buttons}
              style={{ marginBottom: 76 }}
            >
              <button
                type="button"
                className={classNames("next-button", classesFirstStep.btn)}
                id="toSecondPage"
                onClick={() => history.push("/bienvenue/step-3")}
              >
                Non
              </button>
              <button
                type="button"
                className={classNames("next-button", classesFirstStep.btn)}
                id="toSecondPage"
                onClick={() => history.push("/bienvenue/step-3")}
              >
                Oui
              </button>
            </div>
            {browser === "safari" ? (
              <>
                <a
                  href=""
                  className={classesFirstStep.blueAction}
                  onClick={(e) => {
                    e.preventDefault();
                    setIsVideo(true);
                  }}
                >
                  Voir la vidéo de démonstration 1
                </a>
                <br />
                <br />
                <a
                  href=""
                  className={classesFirstStep.blueAction}
                  onClick={(e) => {
                    e.preventDefault();
                    setIsSecondVideo(true);
                  }}
                >
                  Voir la vidéo de démonstration 2
                </a>
              </>
            ) : (
              <a
                href=""
                className={classesFirstStep.blueAction}
                onClick={(e) => {
                  e.preventDefault();
                  setIsVideo(true);
                }}
              >
                Voir la vidéo de démonstration
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation("welcome")(SecondStep);
