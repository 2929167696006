import { Redirect, Route, Switch } from "react-router-dom";
import Uninstall from "../../pages/Uninstall";
import FirstStep from "../../pages/FirstStep/FirstStep";
import SecondStep from "../../pages/SecondStep/SecondStep";
import ThirdStep from "../../pages/ThirdStep/ThirdStep";
import FourthStep from "../../pages/FourthStep/FourthStep";
import FifthStep from "../../pages/FifthStep/FifthStep";
import SixthStep from "../../pages/SixthStep/SixthStep";
import { V3_ROUTER_DATA } from "./data";

export const Router = () => {
  return (
    <Switch>
      {V3_ROUTER_DATA.map(({ path, component }) => (
        <Route path={path} exact>
          {component}
        </Route>
      ))}

      <Route path="/uninstall" exact>
        <Uninstall />
      </Route>
      <Route path="/bienvenue" exact>
        <FirstStep />
      </Route>
      <Route path="/bienvenue/step-2" exact>
        <SecondStep />
      </Route>
      <Route path="/bienvenue/step-3" exact>
        <ThirdStep />
      </Route>
      <Route path="/bienvenue/step-4" exact>
        <FourthStep />
      </Route>
      <Route path="/bienvenue/step-5" exact>
        <FifthStep />
      </Route>
      <Route path="/bienvenue/step-6" exact>
        <SixthStep />
      </Route>
      <Route path="/" render={() => <Redirect to="/bienvenue" />} exact />
      <Route path="*" render={() => <Redirect to="/bienvenue" />} />
    </Switch>
  );
};
