import i18n from "i18next";
import Backend from "i18next-chained-backend";
import XHR from "i18next-xhr-backend"; // fallback xhr load
import { initReactI18next } from "react-i18next";

const fallbackLng = ["fr"];
const availableLanguages = ["fr"];

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng,
    debug: true,
    ns: ["welcome"],

    whitelist: availableLanguages,
    react: {
      useSuspense: false,
    },
    backend: {
      allowMultiLoading: false,
      crossDomain: false,
      defaultNS: false,
      backends: [
        XHR, // fallback
      ],
      backendOptions: [
        {
          loadPath: "/locales/{{lng}}/{{ns}}.json", // xhr load path for my own fallback
          allowMultiLoading: false,
          crossDomain: false,
        },
      ],
    },
  });

export default i18n;
