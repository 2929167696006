import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import classnames from "classnames";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";

import { COPY_TITLE } from "../../../copies/copyTitle";
import { getDomain } from "../../../utils/getDomain";
import { getParams } from "../../../utils";
import { detectExtensionBrowser } from "../../../utils/detectBrowser";
import LeaveFeedback from "../../../components/LeaveFeedback";
import FeedbackSent from "../../../components/FeedbackSent";

import styles from "./style.module.scss";

const Uninstall = ({ t }) => {
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    const { cid } = getParams(window.location.href);
    document.querySelector("#root").classList.add("uninstall");
    if (cid) {
      ReactGA.initialize("G-F3JF9CXVS2", {
        gaOptions: {
          clientId: cid,
        },
      });

      const { utm_source } = getParams(window.location.url);
      const { utm_campaign } = getParams(window.location.url);
      ReactGA.event("uninstall_visited", {
        data_source: detectExtensionBrowser(),
        initial_source: utm_source,
        initial_campaign: utm_campaign,
      });
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {COPY_TITLE[getDomain()]} -{" "}
          {t("pageTile", { title: COPY_TITLE[getDomain()] })}
        </title>
      </Helmet>
      <div className={classnames(styles.uninstall)}>
        {isSuccess ? (
          <FeedbackSent t={t} />
        ) : (
          <LeaveFeedback onSuccess={() => setIsSuccess(true)} t={t} />
        )}
      </div>
    </>
  );
};

export default withTranslation("uninstall")(Uninstall);
