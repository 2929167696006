import classnames from "classnames";
import styles from "./style.module.scss";

import { ReactComponent as Navigation } from "../../svg/navagation.svg";
import { ReactComponent as UrLeftPath } from "../../svg/url_left.svg";
import { ReactComponent as UrlRightPath } from "../../svg/ulr_right.svg";

import { ReactComponent as BurgerIcon } from "../../../../svg/browser/burger.svg";
import { ReactComponent as UserIcon } from "../../../../svg/browser/user.svg";
import { ReactComponent as MenuIcon } from "../../../../svg/browser/menu.svg";
import { ReactComponent as ExtensionIcon } from "../../../../svg/browser/extension.svg";
import { ReactComponent as FranceFerifIcon } from "../../../../svg/browser/franceFerif.svg";

const NavBar = ({
  fvActive,
  fvHover,
  fvShow = true,
  extActive,
  extHover,
  extShow = true,
}) => (
  <div className={classnames(styles.wrap, { [styles.opacity]: fvActive })}>
    <div>
      <Navigation />
    </div>
    <div className={styles.input}>
      <UrLeftPath />
      <UrlRightPath />
    </div>
    <div className={classnames(styles.menu)}>
      <p
        className={classnames({
          [styles.active]: fvActive,
          [styles.hover]: fvHover,
          [styles.disabled]: !fvShow,
        })}
      >
        <FranceFerifIcon width={16} height={16} />
      </p>
      <p
        className={classnames({
          [styles.active]: extActive,
          [styles.hover]: extHover,
          [styles.disabled]: !extShow,
        })}
      >
        <ExtensionIcon width={16} height={16} fill="currentColor" />
      </p>
      <MenuIcon width={16} height={16} fill="currentColor" />
      <UserIcon width={24} height={24} fill="currentColor" />
      <BurgerIcon width={3} height={16} fill="currentColor" />
    </div>
  </div>
);
export default NavBar;
