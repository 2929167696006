import classnames from "classnames";
import styles from "./style.module.scss";

import NavBar from "./components/NavBar";

import { ReactComponent as Content } from "./svg/content.svg";
import { ReactComponent as Header } from "./svg/header.svg";

const DEFAULT_WIDTH = 1440;

const Browser = ({
  width = DEFAULT_WIDTH,
  className,
  fvActive,
  fvHover,
  fvShow = true,
  extActive,
  extHover,
  extShow = true,
}) => {
  const scale = width / DEFAULT_WIDTH;
  return (
    <div
      className={classnames("browser", className)}
      style={{ "--scale": scale, "--width": `${width}px` }}
    >
      <div className={styles.wrap}>
        <div className={styles.navigation}>
          <Header className={styles.header} />
          <NavBar
            fvActive={fvActive}
            fvHover={fvHover}
            fvShow={fvShow}
            extActive={extActive}
            extHover={extHover}
            extShow={extShow}
          />
        </div>

        <Content className={styles.content} />
      </div>
    </div>
  );
};
export default Browser;
