import { useHistory, useLocation } from "react-router-dom";
import { useEffect, useMemo, useRef } from "react";
import classnames from "classnames";

export const useAnimationStepper = ({ delaySteps }) => {
  const location = useLocation();
  const history = useHistory();

  const isFirstRender = useRef(true);
  const timeoutId = useRef(null);

  const step = location?.state?.activeStep || 1;

  const setStep = (values, delay) => {
    clearInterval(timeoutId.current);
    timeoutId.current = setTimeout(() => {
      history.push({
        ...location,
        state: {
          activeStep: values,
        },
      });
    }, delay);
  };

  const handlerStartAnimation = (value, firstRenderDelay = 0) => {
    const defaultStepDelay = delaySteps?.[value] || 0;
    const animationDelay = defaultStepDelay + firstRenderDelay;
    const nextStep = value + 1;

    if (!defaultStepDelay) {
      return;
    }

    setStep(nextStep, animationDelay);
  };

  const handlerResetAnimation = () => {
    setStep(1, 1);
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      handlerStartAnimation(step, 600);
      return;
    }

    handlerStartAnimation(step);
  }, [step]);

  const stepClass = classnames({
    step_1: step >= 1,
    step_2: step >= 2,
    step_3: step >= 3,
    step_4: step >= 4,
    step_5: step >= 5,
    step_6: step >= 6,
    step_7: step >= 7,
  });
  return { step, stepClass, handlerResetAnimation };
};
