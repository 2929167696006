import React from "react";
import classes from "src/pages/Welcome/style.module.scss";
import classNames from "classnames";
import { ReactComponent as Logo } from "src/svg/logo-welcome.svg";
import SecondPage from "src/pages/Welcome/compontents/secondPage";

const FourthStep = (props) => (
  <div className={classes.root}>
    <div className={classNames(classes.header)}>
      <div className={classes.logo}>
        <a href="https://franceverif.fr/" target="_blank">
          <Logo />
        </a>
      </div>
    </div>
    <div className={classes.body}>
      <SecondPage />
    </div>
  </div>
);

export default FourthStep;
