import React from "react";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import image from "src/static/images/third-step/Group 30433.png";
import imageWebp from "src/static/images/third-step/Group 30433.webp";
import image2x from "src/static/images/third-step/Group 30433@2x.png";
import image2xWebp from "src/static/images/third-step/Group 30433@2x.webp";
import { useHistory } from "react-router-dom";
import { detect } from "detect-browser";
import { ReactComponent as Logo } from "../../svg/logo-welcome.svg";
import classesFirstStep from "../Welcome/compontents/styles/mainPage.module.scss";
import classes from "../Welcome/style.module.scss";

const ThirdStep = ({ t }) => {
  const history = useHistory();

  const browser = detect().name;

  return (
    <div className={classes.root}>
      <div className={classNames(classes.header)}>
        <div className={classes.logo}>
          <a href="https://franceverif.fr/" target="_blank">
            <Logo />
          </a>
        </div>
      </div>
      <div className={classes.body}>
        <div className={classesFirstStep.root}>
          <div className={classesFirstStep.imageThird}>
            <picture>
              <source
                srcSet={`${imageWebp} 1x, ${image2xWebp} 2x`}
                type="image/webp"
              />
              <img
                src={imageWebp}
                srcSet={`${image2xWebp} 2x`}
                type="image/webp"
                alt={t("firstTitle")}
              />
            </picture>
          </div>
          <div className={classesFirstStep.content}>
            <div className={classesFirstStep.title}>
              Votre assistant achat IA est désormais configuré !
            </div>
            <p
              className={classesFirstStep.firstText}
              style={{ marginBottom: 17, marginTop: 40 }}
            >
              Cliquez à n’importe quel moment sur l’icône France Verif pour:
            </p>
            <ol className={classesFirstStep.notOrderedList}>
              <li>
                <span />
                <p>
                  <b>Analyser rapidement la fiabilité des sites e-commerce</b>
                  <br />
                  (proposant la livraisonde produits physiques)
                </p>
              </li>
              <li>
                <span />
                <p>
                  <b>Rechercher les meilleurs offres et prix</b>
                  <br />
                  sur les produits qui vous intéressent
                </p>
              </li>
              <li>
                <span />
                <p>
                  <b>Bénéficier des réductions et coupons disponibles</b>
                  <br />e manière automatique
                </p>
              </li>
            </ol>
            <div className={classesFirstStep.buttons}>
              <button
                style={{ width: 369 }}
                type="button"
                className={classNames("next-button", classesFirstStep.btn)}
                id="toSecondPage"
                onClick={() => {
                  history.push("/bienvenue/step-4");
                }}
              >
                Suivant
              </button>
            </div>
            {(browser === "chrome" || browser === "safari") && (
              <a
                href=""
                className={classesFirstStep.underlineAction}
                onClick={(e) => {
                  e.preventDefault();
                  history.push("/bienvenue/step-2");
                }}
              >
                Besoin d’aide ?
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation("welcome")(ThirdStep);
