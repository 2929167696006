import { useEffect } from "react";
import { withTranslation } from "react-i18next";
import styles from "./style.module.scss";

import Container from "../../../components/UI/Container";
import Title from "../../../components/UI/Title";
import Button, { ACTION_TYPE } from "../../../components/UI/Button";
import AnimationIcon from "../../../components/UI/AnimationIcon";
import Description from "../../../components/UI/Description";

import { ReactComponent as CheckIcon } from "../../../svg/check.svg";

import { STEP_2_PAGE } from "../../../constants";

const FirstStep = () => {
  useEffect(() => {
    dataLayer?.push({ event: "vue_onboarding_v3" });
  }, []);

  return (
    <Container width={440} marginTop={104}>
      <AnimationIcon size={149} className={styles.icon}>
        <CheckIcon fill="currentColor" />
      </AnimationIcon>

      <p className={styles.titleText}>Parfait !</p>
      <Title className={styles.title}>
        Votre extension est bien installée.
      </Title>
      <Description className={styles.description}>
        Pour bénéficier de toute sa puissance,
        <br />
        <span>configurons-la ensemble.</span>
      </Description>
      <Button width="250px" href={STEP_2_PAGE} actionType={ACTION_TYPE.NEXT}>
        Configurer l’extension
      </Button>
    </Container>
  );
};

export default withTranslation("welcome")(FirstStep);
