import classnames from "classnames";
import styles from "./style.module.scss";

import { Animation } from "../../Animation/Animation";
import Image from "../../UI/Image";

import { ReactComponent as Header } from "./svg/header.svg";
import { ReactComponent as DomainBadgeIcon } from "../../../svg/extension/domain-badge.svg";

const OffersToaster = ({ className, opened = true, click }) => (
  <div className={classnames("offersToaster", className)}>
    <Animation opened={opened} delay={500}>
      <div className={styles.wrap}>
        <div className={styles.header}>
          <Header />
        </div>
        <div className={styles.content}>
          <div className={styles.contentHeader}>
            <p>nike.com</p>
            <p>
              <DomainBadgeIcon width={16} height={16} fill="#169B56" />
              Site fiable
            </p>
          </div>
          <div className={styles.contentBody}>
            <h2 className={styles.title}>
              3 <span>codes promo</span> disponibles
            </h2>
            <p className={styles.description}>À tester sur ce site</p>
            <div
              className={classnames(styles.button, {
                [styles.click]: click,
              })}
            >
              Tester les codes promos
            </div>
            <Image
              src="/images/extension/money"
              width={81}
              height={70}
              className={classnames(styles.money)}
            />
          </div>
        </div>
      </div>
    </Animation>
  </div>
);

export default OffersToaster;
