import React from "react";
import classnames from "classnames";
import styles from "./style.module.scss";

const ButtonUninstall = ({
  type = "primary",
  text,
  classname,
  disabled,
  onClick,
}) => (
  <button
    aria-label="Apply button"
    type="button"
    disabled={disabled}
    className={classnames(classname, styles.button, {
      [styles[`button-${type}`]]: type,
    })}
    onClick={onClick}
  >
    {text}
  </button>
);

export default ButtonUninstall;
