import { useEffect, useRef, useState } from "react";

const useMount = (opened, duration = 0) => {
  const [mounted, setMounted] = useState(opened);

  const timeoutId = useRef();

  useEffect(() => {
    clearInterval(timeoutId.current);

    if (opened && !mounted) {
      setMounted(true);
      return;
    }
    if (!opened && mounted) {
      timeoutId.current = setTimeout(() => {
        setMounted(false);
      }, duration);
    }
  }, [opened]);

  return [mounted];
};

export default useMount;
