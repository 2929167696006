import classnames from "classnames";
import styles from "./style.module.scss";

import { ReactComponent as Menu } from "../../svg/profile/menu.svg";
import { ReactComponent as CodeContent } from "../../svg/profile/code.svg";
import { ReactComponent as Title } from "../../svg/profile/title.svg";
import { ReactComponent as InfoLeft } from "../../svg/profile/info-block-left.svg";
import { ReactComponent as InfoRight } from "../../svg/profile/info-block-right.svg";

import AnimationMoney from "../UI/AnimationMoney";
import ScrollAnimation from "../UI/ScrollAnimation";

const DEFAULT_WIDTH = 730;
const DEFAULT_HEIGHT = 519;

const ProfilePage = ({ width = DEFAULT_WIDTH, className, startAnimation }) => {
  const scale = width / DEFAULT_WIDTH;
  const height = DEFAULT_HEIGHT * scale;
  const marginHorizontal = (width - DEFAULT_WIDTH) / 2;
  const marginVertical = (height - DEFAULT_HEIGHT) / 2;

  return (
    <div
      className={classnames(styles.wrap, className)}
      style={{
        "--scale": scale,
        "--margin": `${marginVertical}px ${marginHorizontal}px`,
      }}
    >
      <ScrollAnimation
        data={[10, 11, 12, 13, 14, 15]}
        startAnimation={startAnimation}
        className={styles.animation}
      />
      <Menu />
      <div className={styles.pageContent}>
        <div className={styles.pageWrap}>
          <Title />
          <div className={styles.pageTitle}>
            <InfoLeft style={{ position: "relative", zIndex: 10 }} />
            <InfoRight />
          </div>

          <div className={styles.pageCode}>
            <CodeContent />
            <AnimationMoney width={205} className={styles.money} delay={0} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
