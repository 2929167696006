import {
  HOME_PAGE,
  STEP_2_PAGE,
  STEP_3_PAGE,
  STEP_4_PAGE,
  STEP_5_PAGE,
  STEP_6_PAGE,
  STEP_7_PAGE,
  UNINSTALL_PAGE,
} from "../../constants";
import Uninstall from "../../pages/v3/Uninstall";
import FirstStep from "../../pages/v3/FirstStep/FirstStep";
import SecondStep from "../../pages/v3/SecondStep/SecondStep";
import ThirdStep from "../../pages/v3/ThirdStep/ThirdStep";
import FourthStep from "../../pages/v3/FourthStep/FourthStep";
import FifthStep from "../../pages/v3/FifthStep/FifthStep";
import SixthStep from "../../pages/v3/SixthStep/SixthStep";
import NinthStep from "../../pages/v3/NinthStep/NinthStep";

export const V3_ROUTER_DATA = [
  { path: UNINSTALL_PAGE, component: <Uninstall /> },
  { path: HOME_PAGE, component: <FirstStep /> },
  { path: STEP_2_PAGE, component: <SecondStep /> },
  { path: STEP_3_PAGE, component: <ThirdStep /> },
  { path: STEP_4_PAGE, component: <FourthStep /> },
  { path: STEP_5_PAGE, component: <FifthStep /> },
  { path: STEP_6_PAGE, component: <SixthStep /> },
  { path: STEP_7_PAGE, component: <NinthStep /> },
];
