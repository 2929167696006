import styles from "./style.module.scss";

function InputUninstall({ label, error, ...otherProps }) {
  return (
    <div className={styles.wrapper}>
      {label && (
        <label htmlFor="input-uninstall" className={styles.label}>
          {label}
        </label>
      )}
      <input
        type="text"
        {...otherProps}
        className={styles.input}
        id="input-uninstall"
      />
      {error && <p className={styles.error}>{error}</p>}
    </div>
  );
}

export default InputUninstall;
