import { withTranslation } from "react-i18next";
import styles from "./style.module.scss";
import classnames from "classnames";

import Container from "../../../components/UI/Container";
import AnimationIcon from "../../../components/UI/AnimationIcon";
import Title from "../../../components/UI/Title";
import Description from "../../../components/UI/Description";
import AnimationBlock from "../../../components/UI/AnimationBlock";
import { Animation } from "../../../components/Animation/Animation";
import Button, { ACTION_TYPE } from "../../../components/UI/Button";
import Browser from "../../../components/Browser";
import OffersToaster from "../../../components/Extension/OffersToaster";
import ApplyToster from "../../../components/Extension/ApplyToster";
import { useAnimationStepper } from "../../../hooks/useAnimationStepper";

import { ReactComponent as SearchIcon } from "../../../svg/search.svg";
import { ReactComponent as CursorIcon } from "../../../svg/cursor.svg";

import { STEP_7_PAGE } from "../../../constants";

const DELAY_TEXT = 600;
const DELAY_STEPS = {
  1: 1500,
  2: 600 + 1500,
  3: 600 + 500,
  4: 600 + 1000,
  5: 600,
  6: 800 + 1000,
};

const SixthStep = () => {
  const { step, stepClass, handlerResetAnimation } = useAnimationStepper({
    delaySteps: DELAY_STEPS,
  });

  return (
    <Container width={730}>
      <AnimationIcon size={88} className={styles.icon}>
        <SearchIcon fill="currentColor" />
      </AnimationIcon>
      <Title className={styles.title}>Trouvez le meilleur prix</Title>
      <Description className={styles.description}>
        Suivez ces étapes
      </Description>

      <AnimationBlock>
        <div className={styles.backdrop}>
          <Animation opened={step < 6} delay={600}>
            <div className={styles.backdropOffers}>
              <Browser width={step > 1 ? 1440 : 730} fvShow />
              <OffersToaster opened={step > 2} click={step === 5} />
              <CursorIcon
                width={24}
                height={24}
                className={classnames(styles.cursor, stepClass)}
              />
            </div>
          </Animation>
          <Animation opened={step >= 6} delay={600}>
            <div className={styles.backdropApply}>
              <ApplyToster />
            </div>
          </Animation>
        </div>

        <div className={styles.bottomText}>
          <Animation opened={step < 3} delay={DELAY_TEXT}>
            <div className={styles.textWrap}>
              <p>
                <span>1.</span>Allez sur la page du produit que vous voulez
                acheter
              </p>
            </div>
          </Animation>
          <Animation opened={step >= 3 && step < 6} delay={DELAY_TEXT}>
            <div className={styles.textWrap}>
              <p>
                <span>2.</span>Les codes promo trouvés s’affichent directement
              </p>
            </div>
          </Animation>
          <Animation opened={step >= 6 && step < 7} delay={DELAY_TEXT}>
            <div className={styles.textWrap}>
              <p>
                <span>3.</span>Bénéficiez de vos réductions !
              </p>
            </div>
          </Animation>
          <Animation opened={step >= 7} delay={DELAY_TEXT}>
            <div className={styles.textWrap}>
              <p className={styles.restart} onClick={handlerResetAnimation}>
                Revoir le tuto
              </p>
              <div>
                <Button
                  actionType={ACTION_TYPE.NEXT}
                  width="144px"
                  className={styles.button}
                  href={STEP_7_PAGE}
                >
                  Suivant
                </Button>
              </div>
            </div>
          </Animation>
        </div>
      </AnimationBlock>
    </Container>
  );
};
export default withTranslation("welcome")(SixthStep);
