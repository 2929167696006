export const UNINSTALL_PAGE = "/uninstall/v3";
export const HOME_PAGE = "/bienvenue/v3";
export const STEP_2_PAGE = "/bienvenue/v3/step-2";
export const STEP_3_PAGE = "/bienvenue/v3/step-3";
export const STEP_4_PAGE = "/bienvenue/v3/step-4";
export const STEP_5_PAGE = "/bienvenue/v3/step-5";
export const STEP_6_PAGE = "/bienvenue/v3/step-6";
export const STEP_7_PAGE = "/bienvenue/v3/step-7";


