import classnames from "classnames";
import styles from "./style.module.scss";

const AnimationIcon = ({ children, size = 88, delay = 0, className }) => {
  return (
    <p
      className={classnames(styles.wrap, className)}
      style={{ "--size": `${size}px`, "--delay": `${delay}ms` }}
    >
      <span className={styles.iconWrap}>{children}</span>
    </p>
  );
};

export default AnimationIcon;
