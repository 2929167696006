// export const API_URL = 'https://dev-api-gateway.ecomcheck.ml';

// export const API_URL = 'https://stage-api-gateway.ecomcheck.ml';

import { getDomain } from "./getDomain";

const API_MAP = {
  "app.franceverif.fr": "https://api-gateway.franceverif.fr",
  "app.frverif.fr": "https://api-gateway.frverif.fr",
};

// TODO: define API_URL via env variable
export const API_URL =
  window.location.host === "ext-page-dev.franceverif.fr" ||
  window.location.host.includes("localhost")
    ? "https://api-gateway-dev.franceverif.fr"
    : API_MAP[getDomain()];
