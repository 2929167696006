import React from "react";
import { ReactComponent as Logo } from "../svg/logo-welcome.svg";
import { ReactComponent as FrVerifLogo } from "../svg/fvVerifLogo.svg";
import { ReactComponent as SmallLogo } from "../svg/small-logo.svg";
import { ReactComponent as FrVerifSmallLogo } from "../svg/frVerifSmallLogo.svg";

export const COPY_LOGOS = {
  "app.franceverif.fr": <Logo />,
  "app.frverif.fr": <FrVerifLogo />,
};

export const COPY_SMALL_LOGO = {
  "app.franceverif.fr": <SmallLogo width={100} height={100} />,
  "app.frverif.fr": <FrVerifSmallLogo />,
};
