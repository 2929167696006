import React from "react";
import classnames from "classnames";
import styles from "./style.module.scss";

const InputUninstall = (props) => {
  const { disabled, error, classname } = props;
  return (
    <>
      <input
        type="text"
        {...props}
        className={classnames(classname, styles.input, {
          [styles.inputDisabled]: disabled,
          [styles.inputError]: error,
        })}
      />
      {error ? <span className={styles.inputErrorText}>{error}</span> : null}
    </>
  );
};

export default InputUninstall;
