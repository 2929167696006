import { withTranslation } from "react-i18next";
import Container from "../../../components/UI/Container";
import AnimationIcon from "../../../components/UI/AnimationIcon";
import styles from "./style.module.scss";
import Title from "../../../components/UI/Title";
import Description from "../../../components/UI/Description";

import { ReactComponent as NailIcon } from "../../../svg/nail.svg";
import { ReactComponent as CursorIcon } from "../../../svg/cursor.svg";
import { ReactComponent as FranceFerifIcon } from "../../../svg/browser/franceFerif.svg";

import AnimationBlock from "../../../components/UI/AnimationBlock";
import Browser from "../../../components/Browser";
import classnames from "classnames";

import { Animation } from "../../../components/Animation/Animation";
import Button, {
  ACTION_TYPE,
  BUTTON_TYPE,
} from "../../../components/UI/Button";
import { STEP_6_PAGE } from "../../../constants";
import Image from "../../../components/UI/Image";
import { useAnimationStepper } from "../../../hooks/useAnimationStepper";

const DELAY_TEXT = 600;
const DELAY_STEPS = {
  1: 1200,
  2: 600 + 1,
  3: 300 + 800,
  4: 600 + 1,
  5: 300 + 1200,
};

const FifthStep = () => {
  const { step, stepClass, handlerResetAnimation } = useAnimationStepper({
    delaySteps: DELAY_STEPS,
  });

  return (
    <Container width={730} marginTop={40}>
      <AnimationIcon size={88} className={styles.icon}>
        <NailIcon fill="currentColor" />
      </AnimationIcon>
      <Title className={styles.title}>
        Epinglez <br />
        <span>votre extension</span>
      </Title>
      <Description className={styles.description}>
        Gagnez du temps en ayant toujours l’extension à portée de clic.
      </Description>
      <AnimationBlock>
        <div className={styles.backdrop}>
          <div className={classnames(styles.test, stepClass)}>
            <Browser
              width={1440}
              className={stepClass}
              extHover={step > 2}
              fvShow={step > 4}
              fvActive={step > 5}
            />

            <Animation opened={step > 5} delay={600}>
              <p className={styles.FV}>
                <FranceFerifIcon width={16} height={16} />
              </p>
            </Animation>
          </div>
          <Image
            src="/images/browser/popup_1"
            className={classnames(styles.popup, styles.popup_1, stepClass)}
          />
          <Image
            src="/images/browser/popup_2"
            className={classnames(styles.popup, styles.popup_2, stepClass)}
          />
          <CursorIcon
            width={24}
            height={24}
            className={classnames(styles.cursor, stepClass)}
          />

          <Animation opened={step > 5} delay={600}>
            <p className={styles.browserText}>
              L’icône devrait apparaître dans le menu
            </p>
          </Animation>
        </div>
        <div className={styles.bottomText}>
          <Animation opened={step === 1} delay={DELAY_TEXT}>
            <div className={styles.textWrap}>
              <p>
                <span>1.</span>Cliquez sur l’icône “Extensions”
              </p>
            </div>
          </Animation>
          <Animation opened={step > 1 && step < 5} delay={DELAY_TEXT}>
            <div className={styles.textWrap}>
              <p>
                <span>2.</span>Trouvez France Verif dans la liste des extensions
              </p>
            </div>
          </Animation>
          <Animation opened={step === 5} delay={DELAY_TEXT}>
            <div className={styles.textWrap}>
              <p>
                <span>3.</span>Cliquez sur l’icône ”Epingle” qui deviendra bleue
              </p>
            </div>
          </Animation>
          <Animation opened={step > 5} delay={DELAY_TEXT}>
            <div className={styles.textWrap}>
              <p onClick={handlerResetAnimation} className={styles.restart}>
                Revoir le tuto
              </p>
              <div>
                <Button
                  width="144px"
                  type={BUTTON_TYPE.MAIN}
                  actionType={ACTION_TYPE.NEXT}
                  className={styles.button}
                  href={STEP_6_PAGE}
                >
                  C’est bon
                </Button>
              </div>
            </div>
          </Animation>
        </div>
      </AnimationBlock>
    </Container>
  );
};

export default withTranslation("welcome")(FifthStep);
