import * as Yup from "yup";

export const getLeaveFeedbackScheme = (t) =>
  Yup.object().shape({
    email: Yup.string()
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        t("invalidEmail")
      )
      .required(t("requiredField")),
    text: Yup.string().required(t("requiredField")),
  });
