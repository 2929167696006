import { useEffect, useState } from "react";
import classnames from "classnames";

import styles from "./style.module.scss";

import AnimationSparkle from "../AnimationSparkle";

import money from "../../../static/images/moneyAnimation/money.png";
import coin7 from "../../../static/images/moneyAnimation/coin-7.png";
import coin9 from "../../../static/images/moneyAnimation/coin-9.png";
import coin10 from "../../../static/images/moneyAnimation/coin-10.png";
import coin11 from "../../../static/images/moneyAnimation/coin-11.png";
import coin12 from "../../../static/images/moneyAnimation/coin-12.png";

const AnimationMoney = ({
  width = 523,
  delay = 1000,
  className,
  sparkleAnimation = "OPACITY",
}) => {
  const [animation, setAnimation] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setAnimation(true);
    }, delay);
  }, []);

  const scale = width / 523;
  const height = 395 * scale;
  const marginHorizontal = (width - 523) / 2;
  const marginVertical = (height - 395) / 2;

  return (
    <div
      className={classnames(styles.wrap, className)}
      style={{
        "--scale": scale,
        "--margin": `${marginVertical}px ${marginHorizontal}px`,
      }}
    >
      <img src={money} className={classnames(styles.money)} />

      <img
        src={coin7}
        width={80}
        height={76}
        className={classnames(styles.coin7, styles.coin, { animation })}
      />
      <img
        src={coin9}
        className={classnames(styles.coin9, styles.coin, { animation })}
      />
      <img
        src={coin10}
        className={classnames(styles.coin10, styles.coin, { animation })}
      />
      <img
        src={coin11}
        className={classnames(styles.coin11, styles.coin, { animation })}
      />
      <img
        src={coin12}
        className={classnames(styles.coin12, styles.coin, { animation })}
      />

      <AnimationSparkle
        size={94}
        rotate={6.37}
        className={styles.sparkle1}
        delay={delay}
        animation={sparkleAnimation}
      />
      <AnimationSparkle
        size={60}
        rotate={6.37}
        className={styles.sparkle2}
        delay={delay}
        animation={sparkleAnimation}
      />
      <AnimationSparkle
        size={136}
        rotate={-37.01}
        className={styles.sparkle3}
        delay={delay}
        animation={sparkleAnimation}
      />
    </div>
  );
};

export default AnimationMoney;
