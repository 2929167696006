import { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import styles from "./style.module.scss";

import Container from "../../../components/UI/Container";
import AnimationIcon from "../../../components/UI/AnimationIcon";
import Title from "../../../components/UI/Title";
import AnimationBlock from "../../../components/UI/AnimationBlock";
import Description from "../../../components/UI/Description";
import Button, { ACTION_TYPE } from "../../../components/UI/Button";
import InputText from "../../../components/UI/Inputs/InputText";

import { ReactComponent as MailIcon } from "../../../svg/mail.svg";

import { STEP_4_PAGE } from "../../../constants";
import { useHistory } from "react-router-dom";

const REQUIRED = "Champs requis";
const NOT_VALID_EMAIL = "Adresse email invalide";
const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const SESSION_KEY = "email";

const ThirdStep = ({ t }) => {
  const [emil, setEmail] = useState("");
  const [error, setError] = useState(null);
  const firstRender = useRef(true);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const handlerChange = (value) => {
    const isFirstRender = firstRender.current;
    setEmail(value);

    firstRender.current = false;

    if (isFirstRender && value === "") {
      return;
    }

    if (value === "") {
      sessionStorage.removeItem(SESSION_KEY);
    } else {
      sessionStorage.setItem(SESSION_KEY, value);
    }
    if (!value) {
      setError(REQUIRED);
      return;
    }

    if (!value.match(EMAIL_REGEX)) {
      setError(NOT_VALID_EMAIL);
      return;
    }
    setError(null);
  };

  useEffect(() => {
    const mail = sessionStorage.getItem(SESSION_KEY) || "";
    handlerChange(mail);
  }, []);

  const handleClick = async () => {
    try {
      setIsLoading(true);
      const body = {
        channels: [{ type: "email", value: emil }],
        fields: [{ id: 241341, value: true }],
        dedupeOn: "email",
      };

      await fetch("https://api.reteno.com/api/v1/contact", {
        method: "POST",
        headers: {
          accept: "application/json; charset=UTF-8",
          "content-type": "application/json",
          Authorization:
            "Basic UUFfdGVzdHM6RDA0RkE4OTU1OUQ4QzBERDhBODY3NThFMzU1OTMyODQ=",
        },
        body: JSON.stringify(body),
      });
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
      sessionStorage.removeItem(SESSION_KEY);
      history.push(STEP_4_PAGE);
    }
  };

  return (
    <Container width={700} marginTop={104}>
      <AnimationIcon size={88} className={styles.icon}>
        <MailIcon fill="currentColor" />
      </AnimationIcon>
      <Title className={styles.title}>
        Entrez votre email <br />
        <span>pour recevoir les meilleurs prix</span>
      </Title>
      <AnimationBlock>
        <InputText
          className={styles.input}
          value={emil}
          name="email"
          maxLength={255}
          placeholder="Votre adresse email*"
          error={error}
          onChange={(e) => handlerChange(e.target.value)}
        />
        <p className={styles.label}>
          Pour avoir accès aux meilleurs prix, il faut être réactif.
        </p>
        <Description className={styles.description}>
          Nous voulons vous prévenir à temps et vous aidons à suivre l’évolution
          des produits que vous souhaitez acheter grâce à des alertes par email.
        </Description>
        <Button
          id="submit-email"
          width="258px"
          onClick={handleClick}
          actionType={ACTION_TYPE.NEXT}
          disabled={!emil?.length || error || isLoading}
        >
          Suivant
        </Button>
      </AnimationBlock>
    </Container>
  );
};

export default withTranslation("welcome")(ThirdStep);
