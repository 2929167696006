import { Link } from "react-router-dom";
import classnames from "classnames";

import styles from "./style.module.scss";

export const BUTTON_TYPE = {
  MAIN: "main",
  OUTLINE: "outline",
  DARK: "dark",
};
export const ACTION_TYPE = {
  DEFAULT: "default",
  NEXT: "next-step",
};

const Button = ({
  type = BUTTON_TYPE.MAIN,
  children = "",
  width = "100%",
  className = "",
  disabled = false,
  href = null,
  onClick = null,
  hover = false,
  actionType = ACTION_TYPE.DEFAULT,
  ...restProps
}) => {
  const cn = classnames(styles.mainButton, className, type, actionType, {
    disabled,
    hover,
  });

  if (href) {
    return (
      <Link
        to={href}
        className={cn}
        style={{ "--width": width }}
        {...restProps}
      >
        <span className={styles.buttonText}>{children}</span>
      </Link>
    );
  }

  return (
    <button
      onClick={onClick}
      className={cn}
      style={{ "--width": width }}
      {...restProps}
    >
      <span className={styles.buttonText}>{children}</span>
    </button>
  );
};

export default Button;
