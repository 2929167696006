import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import classnames from "classnames";
import { animated } from "react-spring";
import imageFirst from "src/static/images/fourth-step/Frame 30357.png";
import imageFirstWebp from "src/static/images/fourth-step/Frame 30357.webp";
import imageFirst2x from "src/static/images/fourth-step/Frame 30357@2x.png";
import imageFirst2xWebp from "src/static/images/fourth-step/Frame 30357@2x.webp";
import { useHistory } from "react-router-dom";
import classes from "./styles/secondPages.module.scss";
import image from "../../../static/images/secondPage.png";
import { ReactComponent as Icon } from "../../../svg/welcomeLinksvg.svg";
import image2xWebp from "../../../static/images/secondPage@2x.webp";
import imageWebp from "../../../static/images/secondPage.webp";
import image2x from "../../../static/images/secondPage@2x.png";
import classNames from "classnames";
import classesFirstStep from "./styles/mainPage.module.scss";

export const SecondPageImage = withTranslation("welcome")(({ t, styles }) => (
  <animated.div style={styles} className={classes.images}>
    <picture>
      <source srcSet={`${imageWebp} 1x, ${image2xWebp} 2x`} type="image/webp" />
      <img
        src={image}
        srcSet={`${image2x} 2x`}
        type="image/png"
        alt={t("secondTitle")}
      />
    </picture>
  </animated.div>
));

export const SecondPageContent = withTranslation("welcome")(({ t, styles }) => {
  const [firstCheck, setFirstCheck] = useState(false);
  const [secondCheck, setSecondCheck] = useState(false);

  return (
    <animated.div style={styles} className={classes.content}>
      <div className={classes.title}>{t("secondTitle")}</div>
      <div className={classes.body}>
        <ul className={classes.list}>
          <li dangerouslySetInnerHTML={{ __html: t("listFirst") }} />
          <li dangerouslySetInnerHTML={{ __html: t("listSecond") }} />
          <li dangerouslySetInnerHTML={{ __html: t("listThird") }} />
          <li dangerouslySetInnerHTML={{ __html: t("listFourth") }} />
          <li dangerouslySetInnerHTML={{ __html: t("listFifth") }} />
          <li dangerouslySetInnerHTML={{ __html: t("listSixth") }} />
        </ul>
      </div>
      <div className={classes.check}>
        <div className={classes.firstCheckbox}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label>
            <input
              type="checkbox"
              id="charte"
              name="charte"
              value={firstCheck}
              onChange={() => setFirstCheck(!firstCheck)}
              className={classes.checkbox}
            />
            <span>{t("checkboxFirst")}</span>
          </label>
        </div>
        <div className={classes.secondCheckbox}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label>
            <input
              type="checkbox"
              id="cgu"
              name="cgu"
              value={secondCheck}
              onChange={() => setSecondCheck(!secondCheck)}
              className={classes.checkbox}
            />
            <span>{t("checkboxSecond")}</span>
          </label>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <a
            href="https://franceverif.fr/cgu"
            target="_blank"
            className={classes.checkLink}
            rel="noreferrer"
          >
            <Icon />
          </a>
        </div>
      </div>
      <div className={classes.info}>{t("infoText")}</div>
      <div className={classes.button}>
        <a
          href="https://franceverif.fr"
          className={classnames(classes.btn, {
            [classes.disabled]: !firstCheck || !secondCheck,
          })}
        >
          <span dangerouslySetInnerHTML={{ __html: t("buttonSecond") }} />
        </a>
      </div>
    </animated.div>
  );
});

const SecondPage = ({ t }) => {
  const [firstCheck, setFirstCheck] = useState(false);
  const [secondCheck, setSecondCheck] = useState(false);

  const history = useHistory();

  return (
    <div className={classes.root}>
      <div className={classes.images}>
        <picture>
          <source
            srcSet={`${imageFirstWebp} 1x, ${imageFirst2xWebp} 2x`}
            type="image/webp"
          />
          <img
            src={imageFirstWebp}
            srcSet={`${imageFirst2xWebp} 2x`}
            type="image/webp"
            alt={t("secondTitle")}
          />
        </picture>
      </div>
      <div className={classes.content}>
        <div className={classes.title}>{t("secondTitle")}</div>
        <div className={classes.body}>
          <ul className={classes.list}>
            <li dangerouslySetInnerHTML={{ __html: t("listFirst") }} />
            <li dangerouslySetInnerHTML={{ __html: t("listSecond") }} />
            <li dangerouslySetInnerHTML={{ __html: t("listThird") }} />
            <li dangerouslySetInnerHTML={{ __html: t("listFourth") }} />
            <li dangerouslySetInnerHTML={{ __html: t("listFifth") }} />
            <li dangerouslySetInnerHTML={{ __html: t("listSixth") }} />
          </ul>
        </div>
        <div className={classes.check}>
          <div className={classes.firstCheckbox}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>
              <input
                type="checkbox"
                id="charte"
                name="charte"
                value={firstCheck}
                onChange={() => setFirstCheck(!firstCheck)}
                className={classes.checkbox}
              />
              <span>{t("checkboxFirst")}</span>
            </label>
          </div>
          <div className={classes.secondCheckbox}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>
              <input
                type="checkbox"
                id="cgu"
                name="cgu"
                value={secondCheck}
                onChange={() => setSecondCheck(!secondCheck)}
                className={classes.checkbox}
              />
              <span>{t("checkboxSecond")}</span>
            </label>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <a
              href="https://franceverif.fr/cgu"
              target="_blank"
              className={classes.checkLink}
              rel="noreferrer"
            >
              <Icon />
            </a>
          </div>
        </div>
        <div className={classes.info}>{t("infoText")}</div>
        <div className={classes.button}>
          <a
            onClick={(e) => {
              e.preventDefault();
              history.push("/bienvenue/step-5");
            }}
            href="https://franceverif.fr"
            className={classnames("next-button", classes.btn, {
              [classes.disabled]: !firstCheck || !secondCheck,
            })}
          >
            <span>Suivant</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default withTranslation("welcome")(SecondPage);
