import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

import styles from "./style.module.scss";

import Container from "../../../components/UI/Container";
import AnimationIcon from "../../../components/UI/AnimationIcon";
import Title from "../../../components/UI/Title";
import Description from "../../../components/UI/Description";
import AnimationBlock from "../../../components/UI/AnimationBlock";
import Button, { ACTION_TYPE } from "../../../components/UI/Button";
import InputCheckbox from "../../../components/UI/Inputs/InputCheckbox";

import { ReactComponent as ListIcon } from "../../../svg/list.svg";
import { ReactComponent as FirstIcon } from "../../../svg/second-step/first.svg";
import { ReactComponent as SecondIcon } from "../../../svg/second-step/second.svg";
import { ReactComponent as ThirdIcon } from "../../../svg/second-step/third.svg";

import { STEP_3_PAGE } from "../../../constants";

const LIST_DATA = [
  {
    text: "Nous priorisons systématiquement votre <b>satisfaction</b> et veillons sur vos <b>intérêts économiques</b>.",
    icon: <FirstIcon width={24} height={24} fill="currentColor" />,
  },
  {
    text: "Les informations que nous récoltons servent uniquement à vérifier la <b>fiabilité</b> des sites et à vous proposer les <b>meilleurs prix</b>.",
    icon: <SecondIcon width={24} height={24} fill="currentColor" />,
  },
  {
    text: "Nous respectons scrupuleusement la <b>législation</b> en vigueur et vous gardez en permanence un droit d'accès, de modification, de rectification et de suppression de vos données.",
    icon: <ThirdIcon width={24} height={24} fill="currentColor" />,
  },
];

const SESSION_KEY = "cgu";
const SecondStep = () => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    const checked = JSON.parse(sessionStorage.getItem(SESSION_KEY) || "false");
    setIsChecked(checked);
  }, []);

  const handlerChange = () => {
    setIsChecked((prevState) => {
      if (prevState) {
        sessionStorage.removeItem(SESSION_KEY);
        return !prevState;
      }
      sessionStorage.setItem(SESSION_KEY, "true");
      return !prevState;
    });
  };

  return (
    <Container width={658} marginTop={64}>
      <AnimationIcon size={88} className={styles.icon}>
        <ListIcon stroke="currentColor" />
      </AnimationIcon>
      <Title className={styles.title}>La charte France Verif</Title>
      <Description className={styles.description}>
        Nous tenons à vous offrir la meilleure expérience
      </Description>

      <AnimationBlock>
        <ul className={styles.list}>
          {LIST_DATA.map(({ text, icon }, i) => (
            <li key={i} className={styles.item}>
              <span className={styles.listIcon}>{icon}</span>
              <p
                className={styles.itemText}
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              />
            </li>
          ))}
        </ul>
        <div className={styles.cguWrap}>
          <InputCheckbox
            className={styles.cgu}
            value={isChecked}
            onChange={handlerChange}
            name="cgu"
            label={
              'J’accepte les <a href="https://franceverif.fr/fr/cgu" target="_blank" rel="nofollow noreferrer">conditions générales</a> de France Verif'
            }
          />
          <Button
            width="258px"
            href={STEP_3_PAGE}
            disabled={!isChecked}
            actionType={ACTION_TYPE.NEXT}
          >
            Suivant
          </Button>
        </div>
      </AnimationBlock>
    </Container>
  );
};

export default withTranslation("welcome")(SecondStep);
