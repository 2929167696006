import classnames from "classnames";
import styles from "./style.module.scss";

import { ReactComponent as CrossIcon } from "../../../svg/cross.svg";
import AnimationMoney from "../../UI/AnimationMoney";

const DEFAULT_HEIGHT = 1440;

const ApplyToster = ({ width = DEFAULT_HEIGHT, className }) => {
  const scale = width / DEFAULT_HEIGHT;

  return (
    <div className={classnames("applyToaster", className)}>
      <div className={styles.wrap}>
        <div className={styles.header}>
          <p>marionnaud.com</p>
          <CrossIcon width={20} height={20} fill="#C0BDD1" />
        </div>
        <div className={styles.content}>
          <AnimationMoney width={155} delay={0} />
          <h2 className={styles.title}>
            Vous économisez <span>2€ !</span>
          </h2>
          <div className={styles.contentBody}>
            <div className={styles.contentList}>
              <div className={styles.contentItem}>
                <p>Prix initial</p>
                <p className={styles.price}>39,99 €</p>
              </div>
              <div className={styles.contentItem}>
                <p>
                  <span className={styles.coupon}>COUPON123</span>
                  <span className={styles.cashback}>-5%</span>
                </p>
                <p className={styles.discount}>-2,00 €</p>
              </div>
            </div>

            <p className={styles.description}>Prix après réduction</p>
            <p className={styles.finalPrice}>37,99€</p>
          </div>

          <p className={styles.footer}>
            👆En plus, <span>5% vous seront remboursés</span> dans votre
            cagnotte après votre achat !
          </p>
        </div>
      </div>
    </div>
  );
};
export default ApplyToster;
