import { useRef } from "react";
import classnames from "classnames";
import styles from "./style.module.scss";

import { ReactComponent as CheckedIcon } from "./svg/checked.svg";

const InputCheckbox = ({
  value,
  onChange,
  error,
  name,
  label,
  className,
  disabled,
}) => {
  const ref = useRef();

  const handlerPress = (e) => {
    if (e.key === "Enter") {
      ref.current.click();
    }
  };

  return (
    <div
      onKeyPress={handlerPress}
      className={classnames(styles.inputCheckbox, className, {
        error: !!error,
        disabled,
        checked: value,
      })}
    >
      <label>
        <input
          ref={ref}
          tabIndex={-1}
          checked={value}
          type="checkbox"
          className="visibility_hidden"
          id={name}
          disabled={disabled}
          name={name}
          onChange={onChange}
        />
        <div className={styles.iconWrap}>
          <div
            className={classnames(styles.customCheckbox, {
              error: !!error,
              disabled,
              checked: value,
            })}
          >
            {value && !error && (
              <CheckedIcon width={14} heighe={14} fill="#FFFFFF" />
            )}
          </div>
        </div>
      </label>

      {label && (
        <p
          className={styles.labelText}
          dangerouslySetInnerHTML={{
            __html: label,
          }}
        />
      )}
    </div>
  );
};
export default InputCheckbox;
