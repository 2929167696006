import { withTranslation } from "react-i18next";
import classnames from "classnames";

import styles from "./style.module.scss";
import { useAnimationStepper } from "../../../hooks/useAnimationStepper";

import { Animation } from "../../../components/Animation/Animation";
import Container from "../../../components/UI/Container";
import AnimationIcon from "../../../components/UI/AnimationIcon";
import Title from "../../../components/UI/Title";
import Description from "../../../components/UI/Description";
import Button, { ACTION_TYPE } from "../../../components/UI/Button";
import AnimationBlock from "../../../components/UI/AnimationBlock";
import ProfilePage from "../../../components/ProfilePage";

import { ReactComponent as CashbackIcon } from "../../../svg/cashback.svg";

const DELAY_TEXT = 600;
const DELAY_STEPS = {
  1: 2000,
  2: 600 + 2000,
  3: 600 + 2000,
};

const NinthStep = () => {
  const { step, stepClass, handlerResetAnimation } = useAnimationStepper({
    delaySteps: DELAY_STEPS,
  });

  const handlerGoToHome = () => {
    window.location.href = "https://franceverif.fr/";
  };

  return (
    <Container width={730}>
      <AnimationIcon size={88} className={styles.icon}>
        <CashbackIcon fill="currentColor" />
      </AnimationIcon>
      <Title className={styles.title}>
        La cagnotte,
        <br />
        <span>comment ça marche ?</span>
      </Title>
      <AnimationBlock>
        <div className={styles.pageContainer}>
          <ProfilePage
            className={classnames(styles.profilePage, stepClass)}
            width={step > 1 ? 1611 : 730}
            startAnimation={step > 2}
          />
        </div>
        <div className={styles.bottomText}>
          <Animation opened={step === 2} delay={DELAY_TEXT}>
            <div className={styles.textWrap}>
              <p>
                À chaque achat sur nos sites partenaires*,
                <br /> vous récoltez du
                <span> “cashback”</span> dans votre
                <span> cagnotte</span>.
              </p>
            </div>
          </Animation>
          <Animation opened={step === 3} delay={DELAY_TEXT}>
            <div className={styles.textWrap}>
              <p>
                Vous pouvez ensuite
                <span>
                  {" "}
                  récupérer cet argent
                  <br /> sur votre compte
                </span>
                pour de futurs achats !
              </p>
            </div>
          </Animation>
          <Animation opened={step === 4} delay={DELAY_TEXT}>
            <div className={styles.textWrap}>
              <p className={styles.restart} onClick={handlerResetAnimation}>
                Revoir le tuto
              </p>
              <div>
                <Button
                  actionType={ACTION_TYPE.NEXT}
                  width="150px"
                  className={styles.button}
                  onClick={handlerGoToHome}
                >
                  C'est parti !
                </Button>
              </div>
            </div>
          </Animation>
        </div>
        <Animation opened={step === 2} delay={DELAY_TEXT}>
          <Description className={styles.description}>
            *Chez un marchand partenaire cashback
          </Description>
        </Animation>
      </AnimationBlock>
    </Container>
  );
};
export default withTranslation("welcome")(NinthStep);
