import classnames from "classnames";

import styles from "./style.module.scss";

import { ReactComponent as SparkleIcon } from "../../../svg/sparkle.svg";

const COLORS = {
  GREEN: "#83C428",
  RED: "#CA3F4B",
  ORANGE: "#FF9800",
};

const ANIMATION_TYPE = {
  OPACITY: styles.opacityAnimation,
  GLOWING: styles.glowingAnimation,
};

const AnimationSparkle = ({
  size = 60,
  color = "GREEN",
  rotate = 0,
  delay = 0,
  className,
  animation = "GLOWING",
}) => (
  <div
    className={classnames(styles.wrap, className)}
    style={{
      "--size": `${size}px`,
      "--rotate": `${rotate}deg`,
      "--delay": `${delay}ms`,
    }}
  >
    <div className={styles.sparkleIconWrap}>
      <SparkleIcon className={ANIMATION_TYPE[animation]} fill={COLORS[color]} />
    </div>
  </div>
);

export default AnimationSparkle;
