import styles from "../ThirdStep/style.module.scss";

import Container from "../../../components/UI/Container";
import TariffPlan from "../../../components/TariffPlan";
import AnimationIcon from "../../../components/UI/AnimationIcon";
import Title from "../../../components/UI/Title";
import AnimationBlock from "../../../components/UI/AnimationBlock";
import Description from "../../../components/UI/Description";

import { ReactComponent as MinMAxIcon } from "../../../svg/tariff/min-max.svg";
import { ReactComponent as OptimalIcon } from "../../../svg/tariff/optimal.svg";
import { ReactComponent as BellIcon } from "../../../svg/bell.svg";

const MOC_DATA = [
  {
    tariff: "min",
    active: false,
    icon: <MinMAxIcon width={24} height={24} fill="currentColor" />,
    title: "Minimum",
    button: "Choisir",
    advantagesIcon: "cross",
    advantages: [
      "Vous ne recevrez pas les meilleurs prix",
      "Vous ne recevrez pas nos actualités et offres exclusives",
    ],
  },
  {
    tariff: "optimal",
    active: true,
    icon: <OptimalIcon width={24} height={24} stroke="currentColor" />,
    title: "Modéré",
    button: "Choisir",
    advantagesIcon: "check",
    advantages: [
      "Vous acceptez de recevoir vos alertes meilleurs prix",
      "Vous acceptez de recevoir nos actualités et offres exclusives",
    ],
  },
  {
    tariff: "max",
    active: false,
    icon: (
      <MinMAxIcon
        width={24}
        height={24}
        fill="currentColor"
        style={{ rotate: "180deg" }}
      />
    ),
    title: "Maximum",
    button: "Choisir",
    advantagesIcon: "check",
    advantages: [
      "Vous recevez en plus les offres exclusives de nos partenaires",
    ],
  },
];

const FourthStep = () => {
  return (
    <Container width={1190}>
      <AnimationIcon size={88} className={styles.icon}>
        <BellIcon fill="currentColor" />
      </AnimationIcon>
      <Title className={styles.title}>
        Choisissez votre
        <br />
        <span>niveau d`alerte</span>
      </Title>
      <Description className={styles.description}>
        Vous pouvez modifier vos préférences à tout moment
      </Description>
      <AnimationBlock>
        <ul style={{ display: "flex", gap: "32px" }}>
          {MOC_DATA.map((data) => (
            <TariffPlan data={data} />
          ))}
        </ul>
      </AnimationBlock>
    </Container>
  );
};

export default FourthStep;
