import styles from "./style.module.scss";

function Button({ children, ...otherProps }) {
  return (
    <button type="submit" className={styles.button} {...otherProps}>
      {children}
    </button>
  );
}

export default Button;
