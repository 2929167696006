import { ReactComponent as GreenCheck } from "../../svg/green-check.svg";

import styles from "./FeedbackSent.module.scss";

function FeedbackSent({ t }) {
  return (
    <div className={styles.wrapper}>
      <GreenCheck className={styles.check} />
      <h1 className={styles.title}>{t("successTitle")}</h1>
      <p className={styles.subtitle}>{t("successSubtitle")}</p>
    </div>
  );
}

export default FeedbackSent;
