import React from "react";
import styles from "./style.module.scss";

const TextareaUninstall = ({ label, error, ...otherProps }) => {
  return (
    <div className={styles.wrapper}>
      {label && (
        <label htmlFor="TextareaUninstall" className={styles.label}>
          {label}
        </label>
      )}
      <textarea
        className={styles.textarea}
        id="TextareaUninstall"
        {...otherProps}
      />
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
};

export default TextareaUninstall;
