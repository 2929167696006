import { useState } from "react";
import classnames from "classnames";
import styles from "./style.module.scss";

import { ReactComponent as CrossIcon } from "../../svg/cross.svg";
import { ReactComponent as CheckIcon } from "../../svg/check.svg";

import Button, { ACTION_TYPE, BUTTON_TYPE } from "../UI/Button";
import { STEP_5_PAGE } from "../../constants";

const TYPES = {
  active: {
    hover: BUTTON_TYPE.DARK,
    def: BUTTON_TYPE.MAIN,
  },
  def: {
    hover: BUTTON_TYPE.MAIN,
    def: BUTTON_TYPE.OUTLINE,
  },
};

const TariffPlan = ({ data, className }) => {
  const [hover, setHover] = useState(false);

  const type = data.active ? "active" : "def";
  const hovered = hover ? "hover" : "def";

  return (
    <li
      className={classnames(styles.item, className, data.tariff, {
        active: data.active,
        hover,
      })}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {data.active && <p className={styles.header}>Le plus fréquent</p>}
      <div className={styles.content}>
        <span className={styles.icon}> {data.icon}</span>
        <h2 className={styles.title}>{data.title}</h2>
        <ul className={styles.advantagesList}>
          {data.advantages.map((advantage) => (
            <li className={styles.advantageItem}>
              <span
                className={classnames(
                  styles.advantageIcon,
                  data.advantagesIcon
                )}
              >
                {data.advantagesIcon === "cross" ? (
                  <CrossIcon width={16} height={16} fill="currentColor" />
                ) : (
                  <CheckIcon width={12} height={12} fill="currentColor" />
                )}
              </span>
              {advantage}
            </li>
          ))}
        </ul>
        <Button
          type={TYPES[type][hovered]}
          href={STEP_5_PAGE}
          actionType={ACTION_TYPE.NEXT}
        >
          {data.button}
        </Button>
      </div>
    </li>
  );
};

export default TariffPlan;
