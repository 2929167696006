import { useState } from "react";
import defSrc from "./svg/defaultImg.svg";

const Image = ({ src, defaultSrc = defSrc, format = "jpeg", ...restProps }) => {
  const [img, setImg] = useState(src || defaultSrc);
  const [isError, setIsError] = useState(!src);

  const handlerError = () => {
    if (isError) {
      return;
    }
    setImg(defaultSrc);
    setIsError(true);
  };

  if (isError) {
    return <img loading="lazy" src={img} {...restProps} />;
  }
  return (
    <picture>
      <source srcSet={`${img}.webp 1x, ${img}@2x.webp 2x`} type="image/webp" />
      <img
        loading="lazy"
        srcSet={`${img}.${format} 1x, ${img}@2x.${format} 2x`}
        onError={handlerError}
        {...restProps}
      />
    </picture>
  );
};

export default Image;
