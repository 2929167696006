import { memo, cloneElement, useEffect, useState, useRef } from "react";
import useMount from "../../hooks/useMount";

// ----------USAGE----------
//
// Add in child component next css:
//
// opacity: 0;
// transition-property: opacity;
// transition-duration: ${({ delay }) => delay}ms;
// transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
//
// &.mounted {
//     opacity: 1;
// }
// &.unmounted{
//   opacity: 0.5;
// }

export const Animation = memo(
  ({ children, opened, delay = 250, ...restProps }) => {
    const [isMounted] = useMount(opened, delay);
    const [isFirstRender, setIsFirstRender] = useState(true);

    const timeoutId = useRef(null);

    useEffect(() => {
      if (!opened) {
        setIsFirstRender(true);
        return;
      }

      clearTimeout(timeoutId.current);
      timeoutId.current = setTimeout(() => {
        setIsFirstRender(false);
      }, 50);
    }, [opened]);

    const mounted = opened && !isFirstRender;
    const unmounted = !opened;

    const classes = `${children.props.className} ${mounted ? "mounted" : ""} ${
      unmounted ? "unmounted" : ""
    }`;

    if (!isMounted) {
      return null;
    }

    return cloneElement(children, {
      className: classes,
      delay,
      ...restProps,
    });
  }
);
