import React, { useState } from "react";
import classnames from "classnames";
import { useFormik } from "formik";
import ReactGA from "react-ga4";

import { COPY_SMALL_LOGO } from "../../copies/copyLogos";
import { getDomain } from "../../utils/getDomain";
import { COPY_TITLE } from "../../copies/copyTitle";
import InputUninstall from "../UI/v3Uninstall/InputUninstall";
import TextareaUninstall from "../UI/v3Uninstall/TextareaUninstall";
import Button from "../UI/v3Uninstall/Button";
import Loader from "../Loader";
import { feedbackExtension } from "../../services/api";
import { getParams } from "../../utils";
import { getLeaveFeedbackScheme } from "../../utils/getLeaveFeedbackScheme";
import { detectExtensionBrowser } from "../../utils/detectBrowser";

import styles from "./style.module.scss";

function LeaveFeedback({ onSuccess, t }) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { values, errors, handleChange, handleBlur, handleSubmit } = useFormik({
    validationSchema: getLeaveFeedbackScheme(t),
    validateOnChange: true,
    initialValues: {
      email: "",
      text: "",
    },
    onSubmit: async ({ email, text }) => {
      setIsSubmitting(true);

      const datas = {
        email,
        text,
        client: "uninstall page",
      };
      const response = await feedbackExtension(datas);
      console.log(response);
      setIsSubmitting(false);

      const { cid } = getParams(window.location.href);

      if (cid) {
        const { utm_source } = getParams(window.location.url);
        const { utm_campaign } = getParams(window.location.url);
        ReactGA.event("uninstall_commented", {
          data_source: detectExtensionBrowser(),
          initial_source: utm_source,
          initial_campaign: utm_campaign,
          comment: text,
        });
      }

      if (response?.ok) {
        onSuccess();
      }
    },
  });

  return (
    <div className={classnames(styles.wrap)}>
      <div className={classnames(styles.logo)}>
        {COPY_SMALL_LOGO[getDomain()]}
      </div>
      <h1>{t("leaveFeedbackTitle", { title: COPY_TITLE[getDomain()] })}</h1>
      <p
        className={styles.description}
        dangerouslySetInnerHTML={{ __html: t("leaveFeedbackSubtitle") }}
      />
      {!isSubmitting ? (
        <form
          className={classnames(styles.feedbackForm)}
          onSubmit={handleSubmit}
        >
          <div className={styles.inputWrapper}>
            <InputUninstall
              label={t("inputLabel")}
              placeholder={t("inputPlaceholder")}
              error={errors.email}
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              name="email"
            />
          </div>

          <TextareaUninstall
            label={t("textAreaLabel")}
            placeholder={t("textAreaPlaceholder")}
            onChange={handleChange}
            onBlur={handleBlur}
            name="text"
            error={errors.text}
            value={values.text}
          />
          <Button
            disabled={
              Object.keys(errors).length || !values.text || !values.email
            }
          >
            {t("send")}
          </Button>
        </form>
      ) : (
        <div className={styles.loader}>
          <Loader />
        </div>
      )}
    </div>
  );
}

export default LeaveFeedback;
